import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select } from "antd";

export const ReferidoEdit: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, queryResult, onFinish } = useForm();
    const referidosData = queryResult?.data?.data;

    const handleOnFinish = async (values: any) => {
        onFinish({
          ...values,
          referidos : values.referidos,
        });
      };

    return (
        <Edit canDelete saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical" onFinish={handleOnFinish}>
            
                <Form.Item
                    label=""
                    name={"_id"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    label="Abrev."
                    name={["abrev"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled/>
                </Form.Item>
                <Form.Item
                    label="Nombre"
                    name={["nombre"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled/>
                </Form.Item>
                <Form.Item
                    label="Referidos"
                    name={"referidos"}
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Select mode="tags" tokenSeparators={[',']} />
                </Form.Item>
            </Form>
        </Edit>
    );
};
