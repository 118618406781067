import React from "react";
import { IResourceComponentsProps, BaseRecord, useMany } from "@refinedev/core";
import {
    useTable,
    List,
    EditButton,
    ShowButton,
    TagField,
    DeleteButton,
} from "@refinedev/antd";
import { Table, Space, Button } from "antd";
import { Link } from "react-router-dom";

export const ReferidoList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps } = useTable({
        syncWithLocation: true,
    });

    const copyToClipboard = (referidos: any[]) => {
        const text = referidos?.join(', ') || '';
        navigator.clipboard.writeText(text);
    };

    return (
        <List>
            <Table {...tableProps} rowKey="id">
                <Table.Column
                    title="Acciones"
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                             <ShowButton
                                hideText
                                size="small"
                                recordItemId={record._id}
                            />
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record._id}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record._id}
                            />
                        </Space>
                    )}
                />
                <Table.Column dataIndex="abrev" title="Abrev." />
                <Table.Column dataIndex="nombre" title="Nombre" />
                <Table.Column
                    dataIndex="referidos"
                    title="Referidos"
                    render={(value: any[]) =>
                        <>
                            {value?.map((item, index) => (
                                <TagField key={index} value={item} />
                            ))}
                        </>
                    }
                />
                <Table.Column
                    title=""
                    dataIndex="referidos"
                    render={(referidos: any[]) => (
                        <Button
                            onClick={() => copyToClipboard(referidos)}
                            size="small"
                        >
                            Copiar CIFs
                        </Button>
                    )}
                />
                 <Table.Column dataIndex={["abrev"]} title="Enlace" render={(
                    value: string,
                    record: BaseRecord,
                ) => (
                    <Link to={"https://app.certinae.es/"+value+"/solicitud/"}>{value}</Link>

                )}/>
                
            </Table>
        </List>
    );
};
